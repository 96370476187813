/* @import '~antd/dist/antd.css'; */

body {
  background-color: #dadada;
}

.image-uploader > .ant-upload {
  width: 100% !important;
}
.ant-card-head-title {
  color: rgba(0, 0, 0, 0.45);
}
.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.ant-btn-width-100 {
  width: 100%;
}
.ant-btn-width-100 .ant-btn:first-child {
  width: calc(100% - 32px);
}
.page-header {
  width: 100%;
  border-bottom: 1px solid #d4d4d5;
  padding-bottom: 24px;
}
.ant-layout-header {
  padding: 0 !important;
}
.main-header {
  height: 64px !important;
  background: #fff !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08) !important;
  position: relative;
}
.ant-page-header {
  background: #fff !important;
}
.main-header .right {
  float: right;
  height: 100%;
}
.main-header .right .action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
}
.main-header .right .action > i {
  font-size: 16px;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.65);
}
.main-header .right .action:hover,
.main-header .right .action.ant-popover-open {
  background: #e6f7ff;
}
.main-header .right .search {
  padding: 0;
  margin: 0 12px;
}
.main-header .right .search:hover {
  background: transparent;
}
.main-header .right .account .avatar {
  margin: 0 8px 0 0;
  color: #1890ff;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: middle;
}
.main-header i.trigger {
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: 0 24px;
}
.main-header i.trigger:hover {
  background: #e6f7ff;
}
@media only screen and (max-width: 768px) {
  .main-header .header .ant-divider-vertical {
    vertical-align: unset;
  }
  .main-header .header .name {
    display: none;
  }
  .main-header .header i.trigger {
    padding: 0 12px;
  }
  .main-header .header .logo {
    padding-right: 12px;
    position: relative;
  }
  .main-header .header .right {
    position: absolute;
    right: 12px;
    top: 0;
    background: #fff;
  }
  /* .main-header .header .right .account .avatar {
    margin-right: 0;
  } */
}

.ant-layout-sider-help {
  font-size: 18px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 84px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #001529;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  opacity: 0.8;
}

.ant-layout {
  min-height: 100vh;
  overflow-x: hidden;
}
.logo {
  height: 64px;
  line-height: 58px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.logo img {
  display: inline-block;
  vertical-align: middle;
}
.menu .anticon {
  margin-right: 8px;
}
.menu .ant-dropdown-menu-item {
  width: 160px;
}

/* table */
.ant-table-row .ant-table-total-column {
  background: #f2f4f5;
}

.ant-table-row.ant-table-total-row {
  background: #f2f4f5;
  font-weight: bold;
}

.ant-table-title {
  background-color: #fafafa;
}

.editable-cell {
  position: relative;
}
.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}
.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.ant-divider-inner-text {
  opacity: 0.5;
}

/* customer logo */
.logo-uploader > .ant-upload {
  width: 64px;
  height: 64px;
  cursor: pointer;
}

.custom-filter-dropdown {
  width: 250px;
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

/* timeline */
.react-calendar-timeline * {
  box-sizing: border-box;
}

.react-calendar-timeline .rct-outer {
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

.react-calendar-timeline .rct-scroll {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.react-calendar-timeline .rct-item:hover {
  z-index: 88;
}

.react-calendar-timeline .rct-item .rct-item-content {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
}

.react-calendar-timeline .rct-sidebar {
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid #bbb;
}
.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
  border-right: 0;
  border-left: 1px solid #bbb;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid #bbb;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background: rgba(0, 0, 0, 0.05);
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
  background: transparent;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  position: absolute;
  border-left: 1px solid #bbb;
  z-index: 30;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-left-width: 2px;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: rgba(250, 246, 225, 0.5);
}

.react-calendar-timeline .rct-horizontal-lines {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #bbb;
  box-sizing: border-box;
  z-index: 40;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: rgba(0, 0, 0, 0.05);
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: transparent;
}

.react-calendar-timeline .rct-cursor-line {
  position: absolute;
  width: 2px;
  background: #2196f3;
  z-index: 51;
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid #bbb;
  cursor: pointer;
  font-size: 14px;
  background-color: #f0f0f0;
  border-left: 2px solid #bbb;
}

.react-calendar-timeline .rct-dateHeader-primary {
  background-color: initial;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  /* color: #fff; */
}

.react-calendar-timeline .rct-header-root {
  background: white;
  border-bottom: 1px solid #bbb;
}

.react-calendar-timeline .rct-calendar-header {
  border: 1px solid #bbb;
}

.progress-row {
  font-size: 12px;
}

.page-header-with-notices .ant-page-header-heading{
  padding-top: 12px;
}

.large.ant-badge-status .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

/* quill */

.ql-editor {
  min-height: 100px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px;
  border-top-color: #ccc;
  border-top-style: solid;
}

.ant-comment .ant-typography blockquote {
  font-size: 12px;
  font-style: italic;
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.ticket-overdue-divider::before {
  border-width: 1px !important;
  border-color: #ff0000 !important;
}

.ticket-overdue-divider .ant-divider-inner-text {
  font-size: 2em;
  opacity: 1;
}

.ticket-overdue-divider::after {
  border-width: 1px !important;
  border-color: #ff0000 !important;
}
