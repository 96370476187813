.loginpagewrap {
  padding-top: 50px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.loginpagewrap p {
  text-align: center;
  font-size: 27px;
  font-family: "cursive";
  margin-top: 10%;
  margin-bottom: 1.4%;
  font-weight: 700;
  color: #888;
}
.loginWrap {
  width: 24%;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 2%;
  border-radius: 5px;
}
.loginWrap .loginBtn {
  width: 100%;
}
